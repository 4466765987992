import ApiService from '../../assets/js/api.service';
import JwtService from '../../assets/js/jwt.service';
import {
    MUT_CLOSE_CREATOR_CHANGE_MODAL,
    MUT_CLOSE_PROFILE_MODAL, MUT_CLOSE_WHDWL_MODAL,
    MUT_EXEC_CHECK_ME_CALL,
    MUT_REMOVE_SESSION,
    MUT_SET_AUTH,
    MUT_SET_AUTH_RES,
    MUT_SET_CHECK_ME_FUNC,
    MUT_SET_CHECK_ME_INFO,
    MUT_SET_SESSION,
    MUT_SHOW_CREATOR_CHANGE_MODAL,
    MUT_SHOW_PROFILE_MODAL,
    MUT_SHOW_WHDWL_MODAL,
    MUT_SHOW_RETERMS_MODAL,
    MUT_CLOSE_RETERMS_MODAL,
} from '../_mut_consts';
import {
    ACT_AUTH_PWD_CHECK,
    ACT_CHECK_DUPLICATE_ID,
    ACT_CHECK_SESSION,
    ACT_GET_SESSION,
    ACT_LOGIN_USER,
    ACT_REMOVE_AUTH_TOKEN,
    ACT_SAVE_CREATOR,
    ACT_SET_AUTH_TOKEN,
    ACT_UPLOAD_IDENTITY,
    ACT_GET_IDENTITY_FILE,
    ACT_IDENTITY_FILE_DOWNLOAD,
    ACT_SIGNUP_USER,
    ACT_UPDATE_PROFILE,
    ACT_GET_CREATOR,
    ACT_START_CHECKME,
    ACT_CHECK_FIND_ID,
    ACT_CHECK_CHANGE_PASSWD,
    ACT_GET_USER_FIND_ID_LIST,
    ACT_GET_USER_FIND_PASSWORD,
    ACT_UPDATE_USER_PASSWORD_CHANGE,
    ACT_CHECK_AUTH_RES,
    ACT_INSERT_CASHFRIENDS_LOG,
    ACT_USER_WHDWL,
    ACT_UPDATE_LGN_FAIL_MTRY_YMD,
    ACT_CHECK_COMP_INFO,
    ACT_GET_LMS_LIST,
    ACT_UPDATE_USER_RETERMS_AGREE,
    ACT_CHECK_SSO_DUPLICATE_BRNO,
    ACT_AFTER_SSO_LOGIN_USER,
    ACT_CHECK_SSO_DUPLICATE_ID,
    ACT_INSERT_SSO_USER_TRANS_MAPPING,
    ACT_INSERT_SSO_USER_TRANS_ADD,
    ACT_CHECK_SSO_VERIFY_PWD,
    ACT_CHECK_SSO_LOGIN_INFO, 
    ACT_UPDATE_SSO_LGN_FAIL_MTRY_YMD,
    ACT_UPDATE_MKT_RCPTN_AGRE_YN,
    ACT_DRMNCY_SSO_USER_RESTORE,
    ACT_UPDATE_OUT_MKT_RCPTN_AGRE_YN,
    ACT_DRMNCY_SSO_USER_RESTORE_CHK,
} from '../_act_consts';

const state = {
    isAuth: !!JwtService.getToken(),
    session: null,
    profile: {
        show: false,
    },
    creator: {
        show: false
    },
    whdwl: {
        show: false,
    },
    checkMeFuncs: [],
    checkMeInfo: {},
    resValidate: false,
    reTerms: {
        show: false,
    },
}

const getters =  {
    session (state) {
        return state.session !== null ? state.session : {};
    },
    isAuth (state) {
        return state.isAuth;
    },
    profile (state) {
        return state.profile;
    },
    creatorModal (state) {
        return state.creator;
    },
    whdwlModal (state){
        return state.whdwl;
    },
    checkMeFuncs (state) {
        return state.checkMeFuncs;
    },
    checkMeInfo (state) {
        return state.checkMeInfo;
    },
    reTerms (state) {
        return state.reTerms;
    }
}

const mutations = {
    [MUT_EXEC_CHECK_ME_CALL](state, info) {
        // console.log( 'calllback ... '+ info.key)
        if(state.checkMeFuncs && state.checkMeFuncs[info.key] ) {
            // console.log(state.checkMeFuncs[info.key] )
            state.checkMeFuncs[info.key](info.value)
            state.checkMeFuncs = []
        } else {

        }
    },
    [MUT_SET_CHECK_ME_FUNC](state, func){
        // console.log('set checkme func....')
        // console.log(func.callfunc)
        if(func) {
            state.checkMeFuncs[func.name] = func.callfunc
            // console.log(state.checkMeFuncs)
        } else {
            state.checkMeFuncs = []
        }
    },
    [MUT_SET_CHECK_ME_INFO](state,info) {
        state.checkMeInfo = info
    },
    [MUT_SET_AUTH](state){
        state.isAuth = !!JwtService.getToken();
    },
    [MUT_SET_SESSION](state, session){
        state.session = session;
        state.isAuth = true;
    },
    [MUT_REMOVE_SESSION](state){
        state.session = null;
        state.isAuth = false;
    },
    [MUT_SHOW_PROFILE_MODAL](state){
        state.profile.show = true;
    },
    [MUT_CLOSE_PROFILE_MODAL](state){
        state.profile.show = false;
    },
    [MUT_SHOW_CREATOR_CHANGE_MODAL](state){
        state.creator.show = true;
    },
    [MUT_CLOSE_CREATOR_CHANGE_MODAL](state){
        state.creator.show = false;
    },
    [MUT_SHOW_WHDWL_MODAL](state){
        console.log('MUT_SHOW_WHDWL_MODAL');
        state.whdwl.show = true;
    },
    [MUT_CLOSE_WHDWL_MODAL](state){
        state.whdwl.show = false;
    },
    [MUT_SET_AUTH_RES](state){
        state.resValidate = true;
    },
    [MUT_SHOW_RETERMS_MODAL](state){
        state.reTerms.show = true;
    },
    [MUT_CLOSE_RETERMS_MODAL](state){
        state.reTerms.show = false;
    },
}

const actions = {
    [ACT_LOGIN_USER] ({ commit }, info){
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/sso/chk', info).then(response => resolve(response));
        });
    },
    [ACT_AFTER_SSO_LOGIN_USER] ({ commit }, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/auth/sso', params).then(response => resolve(response));
        });
    },
    [ACT_GET_SESSION](){
        return new Promise(resolve => {
            ApiService.get('/v1/app/auth').then(response => resolve(response));
        });
    },
    [ACT_CHECK_SESSION](){
        return new Promise(resolve => {
            ApiService.update('/v1/app/auth', '', '', true).then(response => resolve(response));
        });
    },
    [ACT_SET_AUTH_TOKEN]({commit}, authToken){
        JwtService.saveToken(authToken);
        // async
        commit(MUT_SET_AUTH);
    },
    [ACT_REMOVE_AUTH_TOKEN]({commit}){
        JwtService.destroyToken();
        commit(MUT_REMOVE_SESSION);
    },
    [ACT_IDENTITY_FILE_DOWNLOAD]({}, fileName){
        return new Promise(resolve => {
            ApiService.download('/v1/app/auth/user/idnty', 'atch', 0, fileName);
        });
    },
    [ACT_GET_IDENTITY_FILE]({}){
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/user/idnty').then(response => resolve(response));
        });
    },
    [ACT_AUTH_PWD_CHECK]({}, passwd){
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/password/check', passwd).then(response => resolve(response));
        });
    },
    [ACT_SIGNUP_USER]({}, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/signup', params).then(response => resolve(response));
        });
    },
    [ACT_START_CHECKME]({}, kind) {
        if( kind === 1) {
            return new Promise(resolve => {
                ApiService.query('/v1/app/auth/findinfostart').then(response => resolve(response));
            });
        } else {
            return new Promise(resolve => {
                ApiService.query('/v1/app/auth/checkmestart').then(response => resolve(response));
            });
        }
    },
    [ACT_CHECK_FIND_ID]({}, info) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/findId', info).then(response => resolve(response));
        });
    },
    [ACT_CHECK_CHANGE_PASSWD]({}, info) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/changePasswd', info).then(response => resolve(response));
        });
    },
    [ACT_CHECK_DUPLICATE_ID]({}, lgnId){
        return new Promise(resolve => {
            ApiService.query('/v1/app/auth/duplicated', {lgnId: lgnId}).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_PROFILE]({}, params){
        return new Promise(resolve => {
            ApiService.put('/v1/app/auth/profile', params).then(response => resolve(response));
        });
    },
    [ACT_GET_CREATOR]({}){
        return new Promise(resolve => {
            ApiService.query('/v1/app/auth/ctr', {}).then(response => resolve(response));
        });
    },
    [ACT_SAVE_CREATOR]({}, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/ctr', params).then(response => resolve(response));
        });
    },
    [ACT_GET_USER_FIND_ID_LIST]({}, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/user/find/ids', params).then(response => resolve(response));
        });
    },
    [ACT_GET_USER_FIND_PASSWORD]({}, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/user/find/password', params).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_USER_PASSWORD_CHANGE]({}, params){
        return new Promise(resolve => {
            ApiService.put('/v1/app/auth/password/change', params).then(response => resolve(response));
        });
    },
    [ACT_CHECK_AUTH_RES](){
        return new Promise(resolve => {
            ApiService.query('/v1/app/auth/validate', {}).then(response => resolve(response));
            // ApiService.query('/v1/app/test/?test=aaa', {}).then(response => resolve(response));
           
        });
    },
    [ACT_UPLOAD_IDENTITY]({}, files){
        return new Promise(resolve => {
            ApiService.upload('/v1/app/auth/idnty', files).then(response => resolve(response));
        });
    },
    [ACT_INSERT_CASHFRIENDS_LOG] ({}, lgnId, clbParam, secYn, resCd){
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/cashfriends', lgnId , clbParam , secYn , resCd ).then(response => resolve(response));
        });
    },
    [ACT_USER_WHDWL] ({}, lgnId){
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/userWhdwl', lgnId).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_LGN_FAIL_MTRY_YMD]({}, params){
        return new Promise(resolve => {
            ApiService.put('/v1/app/auth/updateLgnFailMtry', params).then(response => resolve(response));
        });
    },
    [ACT_CHECK_COMP_INFO]({}, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/user/validate/comp', params).then(response => resolve(response));
        });
    },
    [ACT_GET_LMS_LIST]({}, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/user/lms', params).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_USER_RETERMS_AGREE]({}, params){
        return new Promise(resolve => {
            ApiService.put('/v1/app/auth/reTermsAgree', params).then(response => resolve(response));
        });
    },
    [ACT_CHECK_SSO_DUPLICATE_BRNO]({}, brNo){
        return new Promise(resolve => {
            ApiService.query('/v1/app/auth/sso/duplicateByBrno', {brNo: brNo}).then(response => resolve(response));
        });
    },
    [ACT_CHECK_SSO_DUPLICATE_ID]({}, ssoId){
        return new Promise(resolve => {
            ApiService.query('/v1/app/auth/sso/duplicateById', {ssoId: ssoId}).then(response => resolve(response));
        });
    },
    [ACT_INSERT_SSO_USER_TRANS_MAPPING]({}, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/sso/transMappingSsoUser', params).then(response => resolve(response));
        });
    },
    [ACT_INSERT_SSO_USER_TRANS_ADD]({}, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/sso/transAddSsoUser', params).then(response => resolve(response));
        });
    },
    [ACT_CHECK_SSO_VERIFY_PWD]({}, ssoPw){
        return new Promise(resolve => {
            ApiService.query('/v1/app/auth/sso/verifyPw', {ssoPw: ssoPw}).then(response => resolve(response));
        });
    },
    [ACT_CHECK_SSO_LOGIN_INFO]({}, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/auth/sso/loginInfoCheck', params).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_SSO_LGN_FAIL_MTRY_YMD]({}, params){
        return new Promise(resolve => {
            ApiService.put('/v1/app/auth/sso/updateLgnFailMtry', params).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_MKT_RCPTN_AGRE_YN]({}, params){
        return new Promise(resolve => {
            // ApiService.post(`/v1/app/auth/outMktRcptnAgree?userData=${params.userData}&mktAgreYn=${params.mktAgreYn}`, {}).then(response => resolve(response));
            ApiService.post(`/v1/app/auth/mktRcptnAgree?mktAgreYn=${params.mktAgreYn}`, {}).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_OUT_MKT_RCPTN_AGRE_YN]({}, params){
        return new Promise(resolve => {
            ApiService.post(`/v1/app/auth/outMktRcptnAgree?userData=${params.userData}&mktAgreYn=${params.mktAgreYn}`, {}).then(response => resolve(response));
            // ApiService.post(`/v1/app/auth/mktRcptnAgree?mktAgreYn=${params.mktAgreYn}`, {}).then(response => resolve(response));
        });
    },
    [ACT_DRMNCY_SSO_USER_RESTORE]({}, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/sso/restoreUser', params).then(response => resolve(response));
        });
    },
    [ACT_DRMNCY_SSO_USER_RESTORE_CHK]({}, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/auth/sso/chkRestoreUser', params).then(response => resolve(response));
        });
    },
}

export const auth = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
